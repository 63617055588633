.hug-h1,
.hug-h2,
.hug-h3,
.hug-h4,
.hug-h5 {
    font-weight: 500 !important;
    line-height: 140%;
}

.mat-typography .hug-h1,
.hug-h1 {
    font-size: rem(36px);
}

.mat-typography .hug-h2,
.hug-h2 {
    font-size: rem(32px);
}

.mat-typography .hug-h3,
.hug-h3 {
    font-size: rem(24px);
}

.mat-typography .hug-h4,
.hug-h4 {
    font-size: rem(20px);
}


@media only screen and (max-width: 1399.98px) {

    .mat-typography .hug-h1,
    .hug-h1 {
        font-size: rem(32px);
    }

    .mat-typography .hug-h2,
    .hug-h2 {
        font-size: rem(24px);
    }

    .mat-typography .hug-h3,
    .hug-h3 {
        font-size: rem(20px);
    }

    .mat-typography .hug-h4,
    .hug-h4 {
        font-size: rem(18px);
    }
}