.error-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        content: "";
        // background-image: url('../images/bg-404.jpg');
        background-image: url('../../images/bg-404.jpg');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        z-index: 0;
        opacity: .1;
    }

    .container {
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        z-index: 1;
    }
}

.error {
    text-align: center;

    &__img {
        max-width: 100%;
        margin: 0 auto;
    }
}

.mat-typography {
    .error__subtitle {
        font-size: 32px;
        line-height: 40px;
        margin: 12px 0 4px;
        font-weight: 500;
        color: #222932;
    }

    .error__text {
        font-size: 14px;
        color: rgba(#222932, .8);
    }
}