.student-scores-table, .absence-list {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
   tr {
       th{
        vertical-align: bottom;
        background: #eaeef2;
        text-shadow: none;
        padding: 6px 12px;
        font-size: 12px;
        position: sticky;
        top: -1px;
        z-index: 10;
        font-weight: 700;
        border-bottom: 2px solid #F3F3F3;
       }
       td {
        padding: 6px 12px;
        vertical-align: top;
       border-top: 1px solid #F3F3F3;
       font-size: 14px;
       }
       &:nth-child(2n+2) {
        background-color: #FAFCFE;
       }
   }
}