.on-hover-showbtn {
    display: none!important;
    position: absolute !important;
    right: 24px;
    top: 24px;
    z-index: 1;
}
.card-body:hover, 
.card-body:focus,
.card-body:active {
    .on-hover-showbtn {
        display: block!important;
    }
}
app-tutor-session-report {
    > .card {
        border: none;
        height: auto;
        box-shadow: none;
    }
}