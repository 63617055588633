.hug {
    &__card-custom-style {
        width: 100%;
        width: rem(834px) !important;
    }

    &--page-container {
        background-image: url(../../images/hug-login.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($primary , .7);
            z-index: 1;
            content: "";

        }

        >* {
            position: relative;
            z-index: 2;
        }
    }

    &__page-container-inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--card-center {
        // width: rem(810px);
        max-width: rem(800px);
        display: flex;
        gap: 16px;
        justify-content: center;
    }

    &__card {
        background-color: #fff;
        border-radius: rem(8px);
        padding: rem(32px) rem(16px);
        flex: 1 1 50%;
        &.hug__card-sm {
            max-width: rem(256px);
        }
    }

    &--logo-container {
        width: rem(112px);
        height: rem(112px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto rem(16px);
        padding: rem(24px);
        background-color: rgba($primary , .2);
        border-radius: 50%;

        img {
            width: 100%;
        }
    }

    &--card-title {
        h1 {
            font-size: rem(18px);
            font-weight: 600;
            color: #103A71;
            text-align: center;
        }

        p {
            text-align: center;
            font-size: rem(14px);
            font-weight: 400;
        }
    }

    &--btn-custom {
        border-radius: rem(10px) !important;
    }
    

    &--btn-icon {
        width: rem(20px);
        height: rem(20px);
        justify-content: center;
        display: inline-flex;
        background: #fff;
        border-radius: 50%;
        color: $primary;
        .mat-icon {
            height: rem(24px);
            width: rem(24px);
            font-size: rem(20px);
        }
    }

    &--user-info {
        width: rem(320px);
        background-color: #E5ECEE;
        padding: rem(32px) rem(16px);
        border-top-left-radius: rem(8px);
        border-bottom-left-radius: rem(8px);
    }

    &--user-avathar {
        width: rem(94px);
        height: rem(94px);
        border-radius: 50%;
        margin: 0 auto rem(12px);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--username {
        font-size: rem(14px);
        color: #3D3A33;
        font-weight: bold;

        span {
            display: block;
            font-weight: 400;
            color: #696969;
        }
    }

    &--card-data {
        text-align: center;
        padding: rem(55px) rem(32px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        h1 {
            font-size: rem(18px);
        }
     
    }

    &--section-lebel {
        font-size: rem(14px);
        font-weight: 500;
        color: $primary;
        margin-bottom: rem(6px);
    }

    &--section-lebel-red {
        font-size: rem(14px);
        font-weight: 300;
        color: $red;
        margin-bottom: rem(6px);
    }

    &--chip-loop {
        padding: 4px;
        box-sizing: border-box;
        width: 50%;
    }

    &--chip-ontainer {
        background-color: darken($primary , 9%);
        padding: rem(2px) rem(8px);
        border-radius: rem(30px);
        color: #fff;

        .mat-tooltip-trigger {
            font-size: rem(11px);
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &--card-close {
        mat-icon {
            width: auto;
            height: auto;
            font-size: rem(13px);
            color: #fff;
        }
    }

    &--max-h {
        max-height: rem(100px);
        overflow: auto;
    }

    &--section-logout {
        font-size: rem(13px);
        color: $primary;
    }
    &--data-descript {
        font-size: rem(14px);
    }
    &--logout-container {
        width: rem(32px);
        height: rem(32px);
        background-color: #fff;
        padding: 4px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: rem(16px);
        right: rem(32px);
        cursor: pointer;
        z-index: 3;
        a {
            cursor: pointer;
            display: flex;
        }
        img {
            width: 100%;
            opacity: .7;
        }
        &:hover {
            box-shadow: 0px 5px 18px rgba(#000 , .3);
            img {
                opacity: 1;
            }
        }

    }
}
.hug--btn-bordered {
        border: solid 2px #103A71 !important;
        color: #103A71 !important;
        background-color: transparent !important;
        .hug--btn-icon {
            color: #103A71 !important;
        }
    }
@media only screen and (max-width:991px)  {
    .hug {
        &--user-info {

        }
        &--sm-media {
            flex-wrap: wrap;
        }
        &--sm-container {
            max-width: rem(480px);
            margin: 0 auto;
            .hug {
                &--user-info {
                    width: 100%;
                    padding-top: rem(32px);
                    padding-bottom: rem(32px);
                    border-top-right-radius: rem(8px);
                    border-bottom-left-radius: 0;
                }
                &--sm-fill {
                    width: 100%;
                }
                &--card-data {
                    padding-top: rem(32px);
                    padding-bottom: rem(32px);
                }
                &--max-h {
                    max-height: rem(160px);
                }
            }
        }
        &--custom-user {
            padding: rem(12px);
            background-color: rgba(#b0bcc0 , .1);
            border-radius: rem(8px);
            }
            &--section-lebel {
                margin-bottom: 0;
            }
    }
    
}

@media only screen and (max-width:767px) {
    html {
font-size: 12px;
    }
    .hug {
        &__card {
            flex: 1 1 100%;
        }
        &__page-container-inner {
            overflow: auto;
            padding: rem(65px) rem(32px) rem(32px) rem(32px);
            align-items: baseline;  
        }

        &--card-center {
            flex-wrap: wrap;
            max-width: 320px;
        }
    }
}
@media only screen and (max-width:480px) {
    .hug {
        &--card-center {
            max-width: 100%;
        }
    }
}