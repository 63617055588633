.color-block {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
}

.table-medium {
    max-height: 236px;
    overflow: auto;
}
.icon-btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    .mat-icon {
        width: auto;
        height: auto;
    }
    &:hover {
        background-color: rgba(0,0,0,.08);
    }
}

.page-count {
    background: #464646;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    span {
        color: $primary;
        padding: 0 2px;
    }
}