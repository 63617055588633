.table {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  &--hover {
    tbody tr {
      &:hover {
        background-color: rgba($primary, 0.1) !important;
        cursor: pointer;
        td {
          border-color: rgba($primary, 0.1) !important;
        }
        + tr {
          td {
            border-color: rgba($primary, 0.1) !important;
          }
        }
      }
    }
  }
  tr {
    &:nth-child(2n + 2) {
      background-color: #fafcfe;
    }
    td {
      padding: 6px 12px;
    }
    th {
      vertical-align: bottom;
      background: #eaeef2;
      text-shadow: none;
      padding: 6px 12px;
      font-size: 12px;
      position: sticky;
      top: -1px;
      z-index: 10;
      white-space: nowrap;
      font-weight: 700;
    }
  }
}

.table-responsive_height {
  max-height: calc(100vh - 255px);
  &.lg {
    max-height: calc(100vh - 200px);
  }
}
@media only screen and (max-width: 991px) {
  .table-responsive_height {
    max-height: calc(100vh - 299px);
  }
}
@media only screen and (max-width: 767px) {
  .table-responsive_height {
    max-height: calc(100vh - 351px);
  }
}
