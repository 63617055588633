:root {
  --hugcolor-primary: 0, 179, 227;
  --hugcolor-secondary: 27, 61, 110;
  --hugcolor-card1: 227, 136, 24;
  --hugcolor-card2: 15, 61, 116;

  // button
  --hugbtn-height: 48px;
  --hugbtn-minWidth: 150px;

  // input
  --huginput-border-color: #b7bdcb;
  --huginput-border-colorHover: rgba(var(--hugcolor-primary), 1);

  // Progress
  --hugprogress-color-1: #00b3e3;
  --hugprogress-color-2: #6bca95;
  --hugprogress-color-3: #fad609;
  --hugprogress-color-4: #ff8c04;
  --hugprogress-color-5: #ff047d;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(var(--hugcolor-secondary), 1);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-selected {
  background-color: rgba(var(--hugcolor-primary), 1);
}

.hug-tutor-bg {
  min-height: 100vh;
  background: linear-gradient(126.49deg,
      rgba(234, 250, 255, 0.8) 12.89%,
      rgba(246, 250, 243, 0.8) 50.29%,
      rgba(252, 248, 239, 0.8) 89.27%);
  display: flex;
  flex-direction: column;
}

.hug-tutor-banner {
  padding-top: 60px;
  z-index: 0;
}

.hug-container {
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1140px;

  &--tutor-banner {
    max-width: 1320px;
  }
}

.hug-tutor-content {
  display: flex;
  gap: 8%;

  &__left {
    flex: 1 1 auto;
  }

  &__right {
    flex: 0 0 41.66%;
    max-width: 41.66%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 16px;
    }

    .tutor-db-miro-wrap {
      padding-bottom: 16px;
    }

    .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
      margin-top: 10px;
    }

    .section-loader {
      bottom: 16px;
      background: rgba(#f4faf5, 0.65);
    }

    .loader-inner {
      transform: scale(0.6);
    }
  }
}

.hug-tutor-separator {
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;

  img,
  svg {
    height: 72px;
    width: 100%;
    min-width: 1200px;
  }
}

.hug-tutor-attempts {
  background: linear-gradient(180deg, #ffffff 0%, #f8fcff 100%);
  padding: rem(40px) 0 0;
  position: relative;
}

.hug-tutor-attempts__confitti {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  display: block;
  pointer-events: none;
}
// canvas {
//  + .hug-dashboard-page {
//     position: relative;
//     z-index: 3;
// }
// }


.hug-card {
  border-radius: 20px;
  border-radius: 16px;
  color: var(--hugcolor-primary);
  position: relative;

  &__header {
    padding: rem(24px);
    display: flex;
    align-items: center;
    color: currentColor;

    .hug-h3 {
      flex: 1 1 auto;
      margin: 0;

      span {
        display: block;
        @extend .hug-h4;
      }
    }

    .hug-card__count {
      flex: 0 0 auto;
      margin-left: 8px;
      font-weight: 500;
      @extend .hug-h1;
    }
  }

  &__body {
    position: relative;
    border-radius: 0 0 16px 16px;
    overflow: hidden;
  }

  &__graphics {
    display: block;
    width: 100%;
    transition: all 0.1s ease-in-out;
    transform-origin: 0 0;
  }

  &--count {
    color: rgba(var(--hugcolor-primary), 1);
    border: 1px solid rgba(var(--hugcolor-primary), 0.2);
    background-color: rgba(var(--hugcolor-primary), 0.06);
    // border-color: #A7E2F2;
    // background-color: #EDFBFF;
  }

  &--first {
    color: rgba(var(--hugcolor-card1), 1);
    border: 1px solid rgba(var(--hugcolor-card1), 0.8);
    background-color: rgba(var(--hugcolor-card1), 0.06);
    // border-color: #9DCEE8;
    // background-color: #E8F7FF;
  }

  &--second {
    color: rgba(var(--hugcolor-card2), 1);
    background: linear-gradient(#f0f7ff, #f0f7ff) padding-box,
      linear-gradient(to bottom, #f9991e, #4fafdd) border-box;
    border: 1px solid transparent;

    // border-color: #94B3D7;
    // background-color: #F0F7FF;
  }

  &--count,
  &--first,
  &--second {
    margin-bottom: 24px;

    .hug-increment {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translatex(-50%);
      margin-top: -24px;
    }
  }

  &:hover,
  &:focus {
    .hug-card__graphics {
      transform: scale(1.05);
      transform-origin: 0 0;
    }
  }
  &.hug-custom-card-container {
    border-radius: 60px !important;
    border-width: 3px;
    &.hug-card--second {
      border-color: #7B9C7A !important;
      background: linear-gradient(180deg, rgba(54,163,55,0.8855917366946778) 0%, rgba(255,255,255,0) 34%);
      h2 {
        color: #267824 !important;
      }

    }
    &.hug-card--first {
      background: linear-gradient(180deg, rgba(249,232,159,1) 0%, rgba(255,255,255,0) 34%);
      h2 {
        color: #B0930A !important;
      }
    }
    &.hug-card--count {
      background: linear-gradient(180deg, rgba(160,221,255,1) 0%, rgba(255,255,255,0) 34%);
      h2 {
        color: #48B4DD !important;
      }
    }

  }
}
.hug-custom-margin-bottom{
    padding-bottom: 60px !important;
}

.hug-tutor-learned {
  padding: 80px 0 20px;
  z-index: 5;
  position: relative;
  background-image: url("../../images/tutor/tutor-learned.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;
}

.hug-tutor-learned__count {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0;
  position: relative;
  pointer-events: none;

  &__img {
    width: 90px;
    position: absolute;
    left: 110%;
    top: 50%;
    transform: translateY(-50%);
  }

  .mat-error {
    position: absolute;
    top: 100%;
    margin-top: 12px;
    display: inline;
    left: auto;
    right: auto;
    width: 280px;
    line-height: normal;
  }
}

.hug-progress {
  position: relative;
  max-width: 620px;
  margin: 0 auto;

  &__total,
  &__percentage {
    position: absolute;
    left: rem(30px);
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
    z-index: 0;
  }

  &__total {
    background-color: #dee1e2;
    height: 2px;
    right: rem(30px);
  }

  &__percentage {
    height: 4px;
    background: linear-gradient(90deg,
        var(--hugprogress-color-1) 0%,
        var(--hugprogress-color-2) 25.03%,
        var(--hugprogress-color-3) 52.14%,
        var(--hugprogress-color-4) 74.03%,
        var(--hugprogress-color-5) 100.1%);
  }
}

.hug-progress__steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.hug-progress__step {
  width: rem(60px);
  height: rem(60px);
  border-radius: 100%;
  padding: rem(12px);
  display: flex;
  background-color: #fff;
  border: 2px solid #dee1e2;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .hug-progress__name {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    color: rgba($secondary, 0.4);
    font-size: 18px;
    line-height: normal;
    text-align: center;
    margin-top: 8px;
    font-weight: 500;
  }

  &.completed:nth-child(1),
  &.current:nth-child(1) {
    border-color: var(--hugprogress-color-1);
  }

  &.completed:nth-child(2),
  &.current:nth-child(2) {
    border-color: var(--hugprogress-color-2);
  }

  &.completed:nth-child(3),
  &.current:nth-child(3) {
    border-color: var(--hugprogress-color-3);
  }

  &.completed:nth-child(4),
  &.current:nth-child(4) {
    border-color: var(--hugprogress-color-4);
  }

  &.completed:nth-child(5),
  &.current:nth-child(5) {
    border-color: var(--hugprogress-color-5);
  }

  &.completed,
  &.current {
    .hug-progress__name {
      color: $secondary;
    }
  }

  &.current {
    width: rem(120px);
    height: rem(120px);
    padding: rem(24px);

    .hug-progress__name {
      font-size: 20px;
    }
  }

  &:not(.completed):not(.current) {
    img {
      filter: grayscale(1);
    }
  }
}

.hug-tutor-engagement {
  background-color: #fff;
  padding: 40px 0;
  position: relative;
}

.hug-tutor-smiley {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 110px;

  &__card {
    background-color: #f3f5f7;
    flex: 1 1 auto;
    border-radius: 24px;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  &__name {
    background-color: #fff;
    border: 1px solid #ebebeb;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border-radius: 40px;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -20px;
  }
}

.hug-tutor-btns {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.hug-tutor-confirm {
  background-color: #fff;
  padding: 40px 0;

  .hug-dropdown-main {
    max-width: 640px;
    margin: 0 auto;
  }
}

.hug-tutor-engagement+.hug-tutor-confirm {
  padding-top: 0;
}

.hug-tutor-footer {
  background-color: #fff;
  display: block;
  position: relative;
  padding-bottom: 30px;
  flex: 1 1 auto;
}

.hug-copyright {
  text-align: center;
  margin: 0 !important;
}

// input
.hug-label {
  color: rgba(var(--hugcolor-secondary), 1);
  font-size: 14px;
  font-weight: 500;
}

.hug-textarea {
  width: 100%;

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--huginput-border-color) !important;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
  &.mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(var(--hugcolor-primary), 1) !important;
  }
}

// select
.hug-dropdown-main {
  width: 100%;
  display: block !important;
  //overflow: hidden;

  .mat-form-field-flex {
    background-color: #fff;
    padding: 16px 16px 16px 16px !important;
    border-radius: 8px;
    border: 1px solid #c2d9df;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    display: flex;
    flex-direction: column-reverse;
    border-top: none !important;
  }

  .mat-form-field-flex>.mat-form-field-infix {
    padding: 0 !important;
    min-height: 32px;
  }

  .mat-form-field-label-wrapper {
    position: static !important;
    padding: 0 0 4px;
    height: auto;
  }

  .mat-form-field-label {
    position: static !important;
    transform: none !important;
    margin: 0 !important;
    color: rgba(#1b3d6e, 0.6) !important;
    font-size: 14px;
  }

  .mat-form-field-infix .mat-input-element,
  .mat-select-value {
    color: #1b3d6e !important;
    font-size: 16px;
    font-weight: 500;
  }

  .mat-form-field-infix .mat-input-element,
  .mat-select-trigger,
  .mat-form-field-label-wrapper {
    padding-left: 48px !important;
  }

  .mat-select-arrow-wrapper {
    position: relative;
    top: -10px;
  }

  .hug-dropdown-main__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
  }

  &.mat-form-field-disabled {
    opacity: 1;

    .mat-form-field-flex {
      background-color: #eef5f4;
      border: 1px dashed #d6dbda;
    }

    .hug-dropdown-main__icon {
      filter: grayscale(100%);
      opacity: 0.25;
    }

    .mat-select-arrow {
      color: #cdcdcd !important;
    }

    .mat-form-field-label {
      color: #9ba7a7 !important;
    }
  }
}

// datepicker
.hug-datepicker {
  .mat-form-field-suffix {
    position: relative;
    top: -12px;
    right: -3px;
  }

  .mat-form-field-flex {
    cursor: pointer;
  }

  //datepicker icon
  .mat-datepicker-toggle {
    span {
      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        margin: 0 4px;
        color: rgba(0, 0, 0, 0.54);
      }

      svg {
        display: none;
      }
    }

    .mat-ripple,
    .mat-button-focus-overlay {
      display: none;
    }
  }
}

// radio
.hug-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  .mat-radio-outer-circle,
  .mat-radio-container,
  .mat-radio-inner-circle {
    height: 32px;
    width: 32px;
  }

  .mat-radio-label-content {
    padding-left: 8px;
    font-size: 14px;
    color: rgba(var(--hugcolor-secondary), 1);
  }

  .mat-radio-outer-circle {
    border-color: rgba(var(--hugcolor-primary), 0.4);
  }

  .mat-radio-container {
    color: rgba(var(--hugcolor-primary), 1);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: rgba(var(--hugcolor-primary), 1);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: rgba(var(--hugcolor-primary), 1) !important;
  }
}

// increment
.hug-increment {
  display: block;
  width: 100%;
  max-width: 140px;
  color: currentColor;

  .mat-form-field-wrapper {
    background-color: #fff;
    display: inline-flex;
    height: 60px;
    border-radius: 60px;
    border: 1px solid currentColor;
    padding: 0 8px !important;
    color: currentColor;
  }

  .mat-button.mat-primary:not([disabled]),
  .mat-icon-button.mat-primary:not([disabled]),
  .mat-stroked-button.mat-primary:not([disabled]) {
    color: currentColor !important;
  }

  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .mat-form-field-flex {
    align-items: center;
  }

  .mat-form-field-flex>.mat-form-field-infix {
    padding: 0 !important;
    border: none;
    width: auto;
    height: 100%;
  }

  input {
    color: currentColor;
    font-size: 24px;
    text-align: center;
    height: 100%;
    margin: 0;
    font-weight: 500;
  }

  .mat-form-field-prefix .mat-icon-button,
  .mat-form-field-suffix .mat-icon-button {
    height: 32px;
    width: 32px;

    .mat-icon {
      font-size: 20px !important;
    }
  }

  // disabled
  >mat-form-field {
    &.mat-form-field-disabled {
      opacity: 1;

      .mat-form-field-wrapper {
        border: 1px dashed #d9d9d9;
        background: #f3f5f7;
      }

      .mat-form-field-flex {
        opacity: 0.5;
      }
    }
  }
}

.hug-percentage {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;

  &__row {
    display: flex;
  }

  &__label {
    font-size: 13px;
    width: 160px;
    margin-right: 24px;
  }

  &__rail {
    display: flex;
    height: 20px;
    width: 100%;
    background: #ddd;
    border-radius: 4px;

    &--primary {
      .hug-percentage__value {
        background: linear-gradient(269deg, #f9c91e 0%, #f9a11e 100%);
        border-radius: 4px;
      }
    }

    &--secondary {
      .hug-percentage__value {
        background: linear-gradient(269deg, #00b3e3 0%, #007ae3 100%);
        border-radius: 4px;
      }
    }
  }

  &__value {
    width: auto;
    display: block;
  }
}

.is-btn-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  mat-spinner {
    width: 18px !important;
    height: 18px !important;

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

@media only screen and (max-width: 1399.98px) {
  .hug-container {
    max-width: 960px;

    &--tutor-banner {
      max-width: 1020px;
    }
  }

  .hug-tutor-engagement {
    padding: 40px 0;
  }

  .hug-dropdown-main .mat-form-field-flex {
    padding: 12px 12px 12px 16px !important;
  }

  .hug-tutor-content__right .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 12px;
  }

  .tutor-db-miro-wrap {
    padding-bottom: 12px !important;
  }

  .hug-dropdown-main .hug-dropdown-main__icon {
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 100%;
    }
  }

  .hug-dropdown-main .mat-form-field-infix .mat-input-element,
  .hug-dropdown-main .mat-select-trigger,
  .hug-dropdown-main .mat-form-field-label-wrapper {
    padding-left: 40px !important;
  }

  .hug-dropdown-main .mat-form-field-infix .mat-input-element,
  .hug-dropdown-main .mat-select-value {
    font-size: 14px;
  }

  .hug-dropdown-main .mat-form-field-label {
    font-size: 12px;
  }
}

@media only screen and (max-width: 991.98px) {
  .hug-tutor-content {
    flex-direction: column;

    &__left {
      display: none;
    }

    &__right {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }

  .hug-tutor-banner {
    padding-top: 24px;
  }

  .hug-tutor-learned,
  .hug-tutor-engagement {
    padding: 40px 0;
  }

  .hug-card {
    &__header {
      padding: 16px;
      display: flex;
      align-items: center;
      color: currentColor;
    }

    &--count,
    &--first,
    &--second {
      max-width: 380px;
      margin: 0 auto 24px;
    }

    &--first,
    &--second {
      margin-bottom: 52px;
    }
  }

  .hug-tutor-smiley {
    &__card {
      padding: 12px;
      border-radius: 12px;
    }

    &__name {
      height: 24px;
      border-radius: 24px;
      padding: 8px 12px;
      margin-top: -12px;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .hug-progress__steps {
    gap: 8px;
  }

  .hug-progress__step {
    width: 40px;
    height: 40px;
    padding: 8px;

    &.current {
      width: 60px;
      height: 60px;
      padding: 12px;
    }
  }

  .hug-progress__name {
    font-size: 12px !important;
  }

  .hug-progress__total,
  .hug-progress__percentage {
    left: 12px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hug-tutor-smiley {
    gap: 8px;
  }

  .hug-percentage__row {
    flex-direction: column;
  }
}