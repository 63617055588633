.hug-tutor-modal-body {
    padding: 48px 48px 24px;

    .hug-btn {
        line-height: 36px !important;
        padding: 4px 32px !important;
        min-width: 90px !important;
        border-radius: 36px !important;
        font-size: 18px !important;
    }
}
.hug-graphic-success {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;

    &__bg{
        width: 100%;
    }

    &__starMain,
    &__starSub1,
    &__starSub2 {
        position: absolute;
    }    
    
    &__starMain {
        width: 48%;
        left: 50%;
        margin-left: -24%;
    }
    &__starSub1 {
        width: 40px;
        left: 5%;
        bottom: 60px;
    }
    &__starSub2 {
        width: 24px;
        right: 15%;
        bottom: 20%;
    }
}