.card {
  border-color: #e2e9f2;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.card-header {
  padding: 8px 1.25rem;
  color: #00b0e0;
  border-bottom: 1px solid #e2e9f2;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  background: #edf6f9;
}
