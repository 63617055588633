// mat-dialog-container {
//     padding: 0!important;
//     .mat-dialog-title,
//     mat-dialog-content,
//     mat-dialog-actions {
//         padding-left: 24px;
//         padding-right: 24px;
//     }
//     .mat-dialog-title {
//         padding-top: 12px;
//         padding-bottom: 12px;
//     }
//     mat-dialog-content {
//         padding-top: 12px;
//         padding-bottom: 12px;
//     }
//     mat-dialog-actions {
//         padding-top: 12px;
//         padding-bottom: 12px;
//     }
// }
body {
    .mat-dialog-container {
        color: $body-color;
        padding: 0;
        position: relative;
        width: 420px;
    }

    .mat-dialog-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        background-color: $primary;
        color: #fff !important;
        margin: 0;
        padding: 16px 24px;
    }    
    .mat-dialog-content {
        padding: 16px 24px;
        margin: 0;
    }
    .mat-dialog-actions {
        padding: 4px 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        background: #F9F9F9;
        margin: 0;
    }
    .modal-text {
        color: $body-color;
    }

    .mat-flat-button.mat-primary, 
    .mat-raised-button.mat-primary, 
    .mat-fab.mat-primary, 
    .mat-mini-fab.mat-primary {
        background-color: $primary;
    }

    .mat-flat-button, 
    .mat-raised-button, 
    .mat-fab, .mat-mini-fab {
        color: $secondary;
      }
}
