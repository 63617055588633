.card-group-items {
    background-color: rgba($primary , .1);
    border: solid 1px rgba($primary , .2);
    >*{
        color: #42526E;
    }
}
.list-contents {
    h3 {
        color: #424c59;
    }
}
.current-loop {
    border-bottom: dashed 1px rgba($primary , .2);
    &__current-progress {
     .progress-bar {
        height: auto !important;
        border-radius: inherit !important;
}
    }
}
.border-left-line {
    border-left: solid 1px #cedade;
}
.icon-edit {
    color: #212121;
    &:hover {
        color: #000;
    }
}
.pointer {
    cursor: pointer;
}

.bg-warning-custom {
    background-color: #f3d990 !important;
    border-radius: 8px;
    border: solid 1px #efb30d;
    font-size: 14px;
}
.text-warning {
    font-size: 15px;
    span {
        font-size: 20px;
    }
}

@media only screen and (min-width:768px) {
    .current-loop__current-progress .progress {
        max-width: 70%;
    }
}