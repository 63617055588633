html {
  font-size: $base-font;
}
.small,
small {
  font-size: rem(12px);
}
.text-primary-invert {
  color: $primary-invert;
}
.text-secondary-invert {
  color: $secondary-invert;
}
.flex-auto {
  flex: 0 0 auto !important;
}
.hr-dashed {
  border-top-style: dashed;
}
h1,
.h1 {
  font-weight: 700;
}
.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
}
.mat-h2,
.mat-title,
.mat-typography h2 {
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
}
.mat-tab-body-wrapper,
.mat-tab-header {
  max-width: 100%;
}
.force-wrap {
  word-break: break-all;
  overflow-wrap: break-word;
  min-width: 0;
}
.mContent > router-outlet + *,
app-student-list,
app-student-details,
app-districts,
app-district-details,
app-schools,
app-school-details,
app-create-edit-school {
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
}
.page-bg {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: rem(200px);
    background-color: $primary;
    z-index: -1;
  }
}
.text-muted {
  color: #9a9a9a;
}
.weight-500 {
  font-weight: 500 !important;
}
%body-link,
.body-link {
  color: $body-color;
  text-decoration: underline;
  font-weight: 500;
  &:hover,
  &:focus {
    color: $primary;
  }
}

.image-fill {
  position: relative;
  overflow: hidden;
  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.icon-link {
  white-space: nowrap;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: 700;
  align-items: center;
}

app-primary-dashboard {
  @extend .d-flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
/*  Icon class generation
/*  ------------------------------------*/
$icon: 12 14 16 20 24 32 48 60;

@each $size in $icon {
  .icon-#{$size} {
    width: rem($size);
    height: rem($size);
  }
}

.icon,
[class^="icon-"] {
  display: inline-flex;
  background: none;
  border: none;
  padding: 0;
  //vertical-align: inherit;
  vertical-align: middle;

  svg {
    fill: currentColor;
  }

  svg,
  img {
    display: block;
    width: 100%;
    max-height: 100%;
  }
}

.icon-auto {
  display: inline-flex;

  > svg {
    display: block;
  }
}

.svg-icon-hide {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

body {
  position: relative;
  font-size: rem(14px);
  background-color: $body-bg;
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button {
    line-height: 34px;
  }
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $primary;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primary;
  }
}
