body {
    button:focus {
        outline: none;
    }

    .mat-option[aria-disabled="true"].contains-mat-select-search.mat-select-search-no-entries-found {
        height: auto;
      }

    .mat-menu-item {
        white-space: normal;
        line-height: normal;
        height: auto;
        font-size: 13px;
        padding: 8px 16px;
        font-weight: 500;
    }

    .mat-menu-panel {
        margin-top: 6px;
    }

    .mat-form-field {
        max-width: 100%;
    }

    .mat-form-field-flex>.mat-form-field-infix {
        padding: 4px 0px 8px !important;
    }

    .mat-form-field-label-wrapper {
        top: -1.5em;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.1em) scale(.75);
        width: 133.33333%;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: $input-border;
    }

    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
        opacity: 1;
    }

    .mat-form-field-disabled {
        opacity: .45;
    }

    .input-white.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-prefix .mat-icon,
    .input-white.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label,
    .input-white.mat-form-field-appearance-outline.mat-form-field-disabled .mat-select-arrow {
        color: #fff;
    }


    .mat-form-field-label {
        line-height: 17px;
        color: $body-color;
    }

    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
        width: 24px;
        height: 24px;
    }

    textarea.mat-input-element {
        padding: 0;
    }
}

.input-white {
    &.mat-form-field-appearance-outline .mat-form-field-outline {
        color: rgba(255, 255, 255, .9);
    }

    input.mat-input-element {
        color: #fff;
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-width: 2px;
        color: #fff;
    }

    .mat-select-value,
    .mat-form-field-label,
    .mat-select-arrow,
    .mat-form-field-prefix .mat-icon,
    .mat-form-field-suffix .mat-icon,
    &.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
    &.mat-form-field.mat-focused .mat-form-field-label {
        color: #fff;
    }
}

.input-black {
    &.mat-form-field-appearance-outline .mat-form-field-outline {
        color: rgba(0, 0, 0);
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
    &.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-width: 2px;
        color: rgb(0, 0, 0);
    }

    .mat-select-value,
    .mat-form-field-label,
    .mat-select-arrow,
    .mat-form-field-prefix .mat-icon,
    .mat-form-field-suffix .mat-icon,
    &.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
    &.mat-form-field.mat-focused .mat-form-field-label {
        color: rgb(0, 0, 0);
    }
}

textarea.mat-input-element.cdk-textarea-autosize {
    line-height: normal;
}

.no-error {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    &.mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0;
    }
}

.input-group {
    .mat-form-field {
        width: 100%;
    }
}

.file-upload {
    position: relative;
    border: 1px solid rgba(42, 63, 72, 0.4);
    border-radius: 5px;
    display: flex;

    input {
        display: none;
    }

    .file-upload-text {
        flex: 1 1 auto;
        padding: 4px 8px;
        display: flex;
        align-items: center;
    }

    &.btn-only {
        border: none;
        justify-content: center;
    }
}



ngx-mat-select-search {
    position: relative;
    display: block;


    .mat-select-search-inner {
        width: 100% !important;
    }
}

.added-list {
    margin-bottom: 16px;

    .list-loop {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 4px 11px;
        background: #00b0e0;
        border-radius: 4px;
        color: #fff;
        margin-bottom: 5px;
        font-size: 13px;
    }
}

.mat-form-field + .added-list {
    margin-top: -12px;
}

.card-close {
    cursor: pointer;
}

.ellipse {
    min-width: 0;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.a-link {
    font-size: 19px;

    &:hover {
        color: #7cd4ec;
    }
}

.icon-help {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 6px;
    transform: translateY(-50%);
    color: #fff;
    display: inline-block;
    cursor: pointer;

    .mat-icon {
        font-size: 24px;
    }

    &:hover {
        color: $primary;
    }
}

.mat-progress-bar-fill::after {
    background-color: #48b944;
}

.mat-form-field-required-marker {
    color: #ff4081;
}

.google-btn {
    transition: background-color .3s, box-shadow .3s;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: #4285f4;
    width: 100%;
    position: relative;
    height: 48px;

    &::before {
        content: "";
        border-radius: 2px;
        position: absolute;
        left: 1px;
        top: 1px;
        background-image: url(../../images/tutor/btn_google_dark_normal_ios.svg);
        background-repeat: no-repeat;
        width: 46px;
        height: 46px;
    }


    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #1669F2;
    }

    &:focus {
        outline: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
        // box-shadow:
        //     0 -1px 0 rgba(0, 0, 0, .04),
        //     0 2px 4px rgba(0, 0, 0, .25),
        //     0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        cursor: not-allowed;
    }
}

.hug-sub-head {
    color: #23252b;
    font-weight: 500;
}
.hug-font-500 {
    font-weight: 500;
    span {
        font-weight: 400;
    }
}
