.hug-smiley {
    border-radius: 100%;
    border: 2px solid rgba(var(--hugcolor-secondary), 1);
    transition: all .2s ease-in-out;
    position: relative;

    .hug-smiley__face {
        position: absolute;
        margin: 25%;
        width: calc(100% - 50%);
        height: calc(100% - 50%);
    }

    .hug-smiley__eyeLeft,
    .hug-smiley__eyeRight {
        border-radius: 100%;
        width: 20%;
        height: 20%;
        display: block;
        top: 20%;
        position: absolute;
        background-color: rgba(var(--hugcolor-secondary), 1);
    }

    .hug-smiley__eyeLeft {
        left: 10%;
    }

    .hug-smiley__eyeRight {
        right: 10%;
    }

    .hug-smiley__mouth {
        width: 100%;
        height: 100%;
        bottom: 10%;
        margin-left: -50%;
        display: block;
        position: absolute;
        left: 50%;
        border-top: none;
        border-right: none;
        border-bottom: 3px solid rgba(var(--hugcolor-secondary), 1);
        border-left: none;
        border-radius: 0;
    }

    // expressions
    &--verySad {
        .hug-smiley__eyeLeft,
        .hug-smiley__eyeRight {
            border-top: 3px solid transparent;
            border-right: 3px solid transparent;
            border-left: 3px solid transparent;
            border-bottom: 3px solid rgba(var(--hugcolor-secondary), 1);
            border-radius: 50%;
            width: 50%;
            height: 30%;
            top: 10%;
            background-color: transparent;
        }

        .hug-smiley__eyeLeft {
            left: -5%;
        }

        .hug-smiley__eyeRight {
            right: -5%;
        }

        .hug-smiley__mouth {
            bottom: 0;
            border-top-right-radius: 200px;
            border-top-left-radius: 200px;
            background: rgba(var(--hugcolor-secondary), 1);
            height: 40%;
            width: 80%;
            margin-left: -40%;
            border: none;
        }
    }

    &--sad {
        .hug-smiley__mouth {
            border-top: 3px solid rgba(var(--hugcolor-secondary), 1);
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-left: 3px solid transparent;
            border-radius: 50%;
            bottom: -70%;
        }
    }

    &--neutral {
        .hug-smiley__mouth {
            width: 70%;
            height: 70%;
            bottom: 25%;
            margin-left: -35%;
        }
    }

    &--happy {
        .hug-smiley__mouth {
            border-top: 3px solid transparent;
            border-right: 3px solid transparent;
            border-left: 3px solid transparent;
            border-radius: 50%;
        }
    }

    &--veryHappy {
        .hug-smiley__eyeLeft,
        .hug-smiley__eyeRight {
            border-top: 3px solid rgba(var(--hugcolor-secondary), 1);
            border-right: 3px solid transparent;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-radius: 50%;
            width: 50%;
            height: 30%;
            top: 20%;
            background-color: transparent;
        }

        .hug-smiley__eyeLeft {
            left: -5%;
        }

        .hug-smiley__eyeRight {
            right: -5%;
        }

        .hug-smiley__mouth {
            bottom: 0;
            border-bottom-right-radius: 200px;
            border-bottom-left-radius: 200px;
            background: rgba(var(--hugcolor-secondary), 1);
            height: 40%;
            width: 80%;
            margin-left: -40%;
            border: none;
        }
    }
}

.hug-tutor-smiley__card {
    .hug-smiley {
        position: absolute;
        left: 25%;
        right: 25%;
        bottom: 25%;
        top: 25%;
    }

    &.active {
        background-color: #FFF7D9;

        .hug-smiley {
            background: linear-gradient(135deg, #FFCE6D 0%, #FF912C 100%);
            border: none;
        }
    }

    &:hover,
    &:focus {
        cursor: pointer;

        .hug-smiley {
            background: linear-gradient(135deg, #FFCE6D 0%, #FF912C 100%);
            border: none;
            box-shadow: rgba(211, 165, 110, 0.498039) 0px 30px 30px 1px, rgb(245, 245, 245) 0px 20px 10px 1px;
            transform: scale(1.15);

            &--verySad,
            &--sad {
                .hug-smiley__face {
                    animation: wobble;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }

                .hug-smiley__mouth {
                    animation: heartBeat;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }
            }

            &--neutral {
                .hug-smiley__face {
                    animation: swing;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }

                .hug-smiley__mouth {
                    animation: heartBeat;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }
            }

            &--veryHappy,
            &--happy {
                .hug-smiley__face {
                    animation: wobbleY;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }

                .hug-smiley__mouth {
                    animation: heartBeat;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }
            }
        }

        .hug-smiley__eyeLeft,
        .hug-smiley__eyeRight {
            // blink animation
            animation-name: hug-blink-eye;
            animation-delay: 1s;
            animation-duration: 5s;
            animation-iteration-count: infinite;
        }
    }

    &.disabled {
        cursor: default;
        .hug-smiley {
            transform: none;
            box-shadow: none;
            background: none;
            filter: grayscale(1);
            opacity: .4;
            border: 2px solid #747474;

            &--verySad,
            &--sad,
            &--neutral,
            &--veryHappy,
            &--happy {                
                .hug-smiley__face,
                .hug-smiley__eyeLeft,
                .hug-smiley__eyeRight,
                .hug-smiley__mouth {
                    animation: none;
                }
            }
        }
    }
}

@keyframes hug-blink-eye {
    0% {
        transform: perspective(25px) rotateX(-0deg);
    }

    6% {
        transform: perspective(25px) rotateX(-0deg);
    }

    9% {
        transform: perspective(25px) rotateX(-85deg);
    }

    10% {
        transform: perspective(25px) rotateX(-0deg);
    }

    90% {
        transform: perspective(25px) rotateX(-0deg);
    }

    96% {
        transform: perspective(25px) rotateX(-0deg);
    }

    99% {
        transform: perspective(25px) rotateX(-85deg);
    }

    100% {
        transform: perspective(25px) rotateX(-0deg);
    }
}

@keyframes wobbleY {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    15% {
        -webkit-transform: translate3d(0, -25%, 0) rotate(5deg);
        transform: translate3d(0, -25%, 0) rotate(5deg)
    }

    30% {
        -webkit-transform: translate3d(0, 20%, 0) rotate(-3deg);
        transform: translate3d(0, 20%, 0) rotate(-3deg)
    }

    45% {
        -webkit-transform: translate3d(0, -15%, 0) rotate(3deg);
        transform: translate3d(0, -15%, 0) rotate(3deg)
    }

    60% {
        -webkit-transform: translate3d(0, 10%, 0) rotate(-2deg);
        transform: translate3d(0, 10%, 0) rotate(-2deg)
    }

    75% {
        -webkit-transform: translate3d(0, -5%, 0) rotate(1deg);
        transform: translate3d(0, -5%, 0) rotate(1deg)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@media only screen and (max-width:767.98px) {

    .hug-smiley,
    .hug-smiley__eyeLeft,
    .hug-smiley__eyeRight,
    .hug-smiley__mouth {
        border-width: 1px !important;
    }
}