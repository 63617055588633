@use 'sass:math' as math;
$base-font: 16px;
$font-family-base: 'Lato', sans-serif;
//$border-radius: 0;

// Custom
$primary: #00B0E0;
$primary-invert: #FFFFFF;
$secondary: #1B3D6E;
$secondary-invert: #FFFFFF;
$red: #dc3545;
$white: #fff;

// Colors
$body-bg: #F4F8F9;
$body-color: #23252b;
$input-border: rgba(#2A3F48, .4);
$gray-100: #f4f5f7;
$gray-200: #e5e7ea;
$gray-300: #F3F3F3;
$gray-400: #ced4da;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.25;
$h2-font-size: $font-size-base * 1.2;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base * .875;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: $spacer,
    5: ($spacer * 1.25),
    6: ($spacer * 1.5),
    7: ($spacer * 1.75),
    8: ($spacer * 2)
  ),
  $spacers
);

//  framework
@import 'node_modules/bootstrap/scss/bootstrap';

//  mixin
@import 'mixin/map-deep-get',
        'mixin/px-to-rem',
        'mixin/reverseMap',
        'mixin/strip-unit';

//  basic
@import 'pages/landing',
        'pages/layout',
        'pages/form',
        'pages/centerform',
        'pages/table',
        'pages/header',
        'pages/sidebar',
        'pages/list',
        'pages/card',
        'pages/dialog',
        'pages/404',
        'pages/preloaders',
        'pages/main',
        'pages/progres',
        'pages/tab',
        'pages/scores',
        'pages/associates',
        'pages/popup',
        'pages/report',
        'pages/tutor-ui';

//  Tutor
@import 'tutor/typography',
        'tutor/button',
        'tutor/magic',
        'tutor/smiley',
        'tutor/banner',
        'tutor/animatedgraphics';

body{
  overflow: hidden !important;
}

