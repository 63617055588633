.hug-btn {
    line-height: var(--hugbtn-height)!important;
    text-align: center  !important;
    display: inline-flex  !important;
    align-items: center  !important;
    justify-content: center  !important;
    padding: 4px 24px  !important;
    min-width: var(--hugbtn-minWidth)!important;
    border-radius: var(--hugbtn-height)!important;
    font-size: 20px!important;

    &:disabled {
        background: #EBEBEB !important;
        border-color: #EBEBEB !important;
        color: #8D8D8D !important;
        cursor: not-allowed !important;

        &:hover,
        &:focus {
            background: #EBEBEB !important;
            border-color: #EBEBEB !important;
            color: #8D8D8D !important;
        }
    }
}

.hug-btn--primary {
    background-color: rgba(var(--hugcolor-primary), 1);
    color: #fff!important;
}

.hug-btn--primary-outline {
    border: 2px solid rgba(var(--hugcolor-primary), 1)!important;
    color: rgba(var(--hugcolor-primary), 1)!important;
}
.custom-gap .flex-fill {
  margin-right:  16px; /* Adjust this value based on your desired gap size */
}
.custom-gap .flex-fill:last-child {
  margin-right:  0; /* Remove margin from the last item */
}

.sticky-top-title {
    position: sticky;
    top: -13px;
    z-index: 9;
}
.btn-container {
    background-color: #f4f8f9;
    padding: 10px 0px;
    text-align: right;
}
.agl__button-sm {
    line-height: 31px !important;
    font-size: 12px;
}
@media only screen and (max-width: 1399.98px) {
    .hug-btn {
        line-height: 36px !important;
        padding: 4px 12px !important;
        min-width: 120px !important;
        border-radius: 36px !important;
        font-size: 18px !important;
    }
}