.tab-group-area {
  .mat-tab-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    .mat-tab-header {
      flex: 0 0 auto;
    }
    .mat-tab-body-wrapper {
      flex: 1 1 auto;
      overflow: auto;
    }
  }
}
app-progress,
app-scores,
app-overview,
app-absence,
app-associates {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.has-nodata {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $primary;
  img {
    width: 100%;
    max-width: 200px;
    margin: 10px auto;
  }
}
