$sidebar-width: rem(220px);
.mSidebar {
    transition: width .4s ease-in-out;
    overflow: hidden;
    background-color: #2B333E;
    &__sidebar {
        width: $sidebar-width;
        height: 100%;
        overflow: auto;
    }
}
.mSidebar__nav {
    > ul {
        > li {
            > a {
                padding: rem(16px);
                display: flex;
                color: #AEB7C2;
                font-size: rem(15px);
                border-left: 4px solid #2B333E;
                font-weight: 400;
                transition: all .5s;
                align-items: center;
                + ul {
                    display: none;
                }
            }
            &:hover,
            &:focus {
                background-color: rgba(0,0,0,.1);
                > a {
                    color: $white;
                    text-decoration: none;
                }
            }
            &.active {
                background-color: rgba(0,0,0,.2);
                > a {
                    color: $primary;
                    border-left-color: $primary;
                    .icon-12 {
                        overflow: hidden;
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                    + ul {
                        display: flex;
                    }
                }
            }
        }
        ul {
            margin-left: rem(80px);
            display: flex;
            flex-direction: column;
            a {
                display: block;
                position: relative;
                margin: 0 0 12px;
                color: #AEB7C2;
                &:before {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border: 2px solid #AEB7C2;
                    content: "";
                    left: -20px;
                    border-radius: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            li {
                &.active {
                    a {
                        color: #fff;
                        &:before {
                            border: 2px solid $primary;
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .mSidebar {
        flex: 0 0 $sidebar-width;
        transition: flex .4s ease-in-out;
    }
    .has-sidebar {
        .mSidebar {
            flex: 0 0 60px;
        }
    }
}
@include media-breakpoint-down(lg) {
    .mSidebar {
        position: fixed;
        left: -$sidebar-width;
        top: rem(56px);
        bottom: 0;
        width: $sidebar-width;
        z-index: 10;
        transition: left .4s ease-in-out;
    }
    .has-sidebar {
        .mSidebar {
            left: 0;
        }
        .mContent {
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                content: "";
                background-color: rgba(0,0,0,.4);
                z-index: 9;
            }
        }
    }
}
