.mHeader {
  @extend .d-flex;
  @extend .flex-wrap;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  background-color: $white;
  z-index: 100;
  &__menu-btn {
    @extend .d-flex;
    @extend .flex-auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $primary !important;
    }
  }
  &__header {
    @extend .d-flex;
    @extend .flex-fill;
  }
  &__menu-btn,
  &__header {
    padding: rem(8px 20px);
  }
  &__logo {
    height: rem(36px);
    display: inline-block;
    @extend .flex-auto;
    img {
      height: 100%;
      display: block;
    }
  }
  &__user {
    @extend .ml-auto;
    @extend .flex-auto;
    @extend .d-inline-flex;
    @extend .align-items-center;
  }
  &__user-image {
    height: rem(32px);
    width: rem(32px);
    @extend .image-fill;
  }
  &__user-info {
    line-height: normal;
    > p {
      font-weight: 500;
    }
    > .small {
      opacity: 0.6;
    }
  }
  //     &__logo img {
  //     cursor: pointer; // Change cursor to pointer on hover
  //     transition: transform 0.2s ease-in-out;

  //     &:hover {
  //       transform: scale(1.1); // Optional zoom effect
  //     }
  //   }
}

.mHeader-home-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 4px;
    margin-bottom: 2.25px !important;
  }
}
