.mContent {
    &__header {
        background-color: rgba(0,0,0,.02);
    }
}
.mControls {
    @extend .d-flex;
    @extend .flex-wrap;
    //align-items: baseline;
    margin: 0 -6px;
    .input-group {
        width: auto;
        padding: 6px;
        flex: 1 1 auto;
        max-width: 280px;
    }
    &__right {
        @extend .d-flex;
        @extend .flex-wrap;
        //align-items: baseline;
        .input-group {
            flex: 0 0 auto;
            align-self: center;
        }
    }
    &--absolute {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
}
.template-download {
    color: #00b2e2;
    font-weight: 400;
    font-size: 13px;
    text-decoration: underline;
}

.mat-icon.rounded-icon {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
    color: #00b0e0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.nav-link {
    min-width: 140px;
    text-align: center;
    &.active {
        position: relative;
        font-weight: 700;
        &:after {
            content: "";
            width: 100%;
            height: 4px;
            position: absolute;
            left: 0;
            bottom: -4px;
            background-color: $primary;
        }
    }
}
.image-preview {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    img {
        width: 100%;
        height: auto;
    }
}
.inline-list {
  display: flex;
  list-style: square;
  padding: 0;
  margin: 0;
}

.inline-list li {
  margin-left: 20px;
  margin-right: 5px;
}
.required-symbol {
  color: red;
}
.mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
}

@include media-breakpoint-down(md) {
    .mControls {
        .input-group {
            max-width: none;
        }
        &__right {
            .input-group {
                flex: 0 0 auto;
            }
        }
    }
}
