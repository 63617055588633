.list-name-scroll {
  max-height: 220px;
  overflow: auto;
  margin: 4px 0 16px;
}

.list-name {
  margin: 0;

  &.row {
    max-width: 480px;

    >* {
      min-width: 220px;
      margin: 0;
    }
  }

  >* {
    padding: 6px 12px;
    border: 1px solid #eaedf2;
    border-radius: 3px;
    margin-bottom: 4px;

    //border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
    //margin-left: 20px;
    .text {
      color: $body-color;
      font-size: 13px;
      font-weight: 500;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      background-color: #fafcfe;
    }
  }
}

.grid-card {
  height: calc(100% - 48px);
}

.form-lr {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  >* {
    display: flex;
    flex: 1 1 100%;
    margin-bottom: 16px;

    &.fill {
      flex: 1 1 100%;
    }

    &.half {
      flex: 1 1 50%;
      flex-direction: column;

      .form-lr__left.fill,
      .form-lr__right.fill {
        flex: 0 0 auto;
      }
    }
  }
}

.form-lr>*:not(.half):last-child {
  margin-bottom: 0;
}

.form-lr__left,
.form-lr__right {
  line-height: normal;
  font-size: 13px;

  &.fill {
    flex: 1 1 100%;

    &:after {
      display: none;
    }
  }

  &.lg {
    font-size: 15px;
    color: #42526e;
  }
}

.form-lr__left {
  flex: 0 0 140px;
  position: relative;
  color: #42526e;
  font-weight: 700;

  &::after {
    position: absolute;
    content: ":";
    top: 0;
    right: 0;
  }
}

.form-lr__right {
  flex: 1 1 auto;
  //padding-left: 12px!important;
}

.smily {
  font-size: 48px;
  display: inline-block;
  vertical-align: sub;
  line-height: 25px;
}

.smiley-rating {
  display: flex;

  >* {
    flex: 1 1 auto;
    text-align: center;
    align-items: center;
    padding: 0 4px;

    a {
      display: flex;
      flex-direction: column;
      color: $body-color;
      text-decoration: none;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
    }

    img {
      width: 48px;
      display: block;
      margin: 0 auto;
    }

    .smiley-hover,
    .smiley-active {
      display: none;
    }

    &:hover {

      .smiley-normal,
      .smiley-active {
        display: none;
      }

      .smiley-hover {
        display: block;
      }
    }

    &:focus,
    &:active,
    &.active {

      .smiley-normal,
      .smiley-hover {
        display: none;
      }

      .smiley-active {
        display: block;
      }
    }
  }
}

.card-group {
  width: calc(100% / 4);
  display: inline-block;

  .card-loop {
    padding: 12px;
    border: solid 1px #c1c1c1;
    border-radius: 4px;
    width: 100%;

    strong {
      display: block;
      color: $primary;
    }

    span {
      font-size: 13px;
      font-style: italic;
      font-weight: 600;
    }
  }
}
.flex-gap-3 {
  gap: 16px;
}
.hug-custom-m {
  mat-radio-button {
    label {
      margin-bottom: 0 !important;
    }
  }
}
.hug-custom-margin {
  margin-bottom: 40px !important;
  &-lg {
    margin-bottom: 60px !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.hug-progress-container {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  mat-radio-group {
    mat-radio-button {
      .mat-radio-label-content {
        font-size: 18px;
        white-space: pre-line;
      }
    }
  }
}
  @include media-breakpoint-down(md) {
    .hug-progress-container {
      .hug-icon {
        img {
          max-width: 100px;
        }
      }
      .hug-radio-group {
       .mat-radio-button {
        .mat-radio-label-content {
          font-size: 14px;
          line-height: 16px;
        }

       }
      }
     }
  }

@include media-breakpoint-down(xs) {
  .form-lr>* {
    flex-direction: column;
  }

  .form-lr__left,
  .form-lr__right {
    flex: 1 1 auto;
    padding: 0;
  }

  .form-lr__left:after {
    display: none;
  }
  .hug-progress-container {
    .hug-icon {
      img {
        max-width: 70px;
      }
    }
    .hug-radio-group {
     .mat-radio-button {
      .mat-radio-label-content {
        font-size: 12px;
        line-height: 14px;
      }

     }
    }
   }
}

@include media-breakpoint-up(sm) {
  .form-lr__right {
    padding-left: 12px;
  }
}